import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

const sendToAnalytics = (id, name, value) => {  
  logEvent(analytics, "event", {
    eventCategory: "Web Vitals",
    eventAction: name,
    eventValue: Math.round(name === "CLS" ? value * 1000 : value),
    eventLabel: id,
    nonInteraction: true,
  });
}

const reportWebVitals = sendToAnalytics => {
  if (sendToAnalytics && sendToAnalytics instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(sendToAnalytics);
      getFID(sendToAnalytics);
      getFCP(sendToAnalytics);
      getLCP(sendToAnalytics);
      getTTFB(sendToAnalytics);
    });
  }
};

export default reportWebVitals;

reportWebVitals(sendToAnalytics);