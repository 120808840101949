import { createBrowserRouter } from "react-router-dom";
import Interface from "../components/interface/Interface";
import { About, Events, FoodAndDrinks, Home, Music, Place, Privacy } from "../screens";
import LanguageRedirect from "../components/language/Language";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LanguageRedirect />
  },
  {
    path: "/:lang",
    element: <Interface />,
    children: [
      {
        path: "about",
        element: <About />
      },
      {
        path: "food-and-drinks",
        element: <FoodAndDrinks />
      },
      {
        path: "events",
        element: <Events />
      },
      {
        path: "location",
        element: <Place />
      },
      {
        path: "music",
        element: <Music />
      },
      {
        path: "privacy",
        element: <Privacy />
      },
      {
        index: true,
        element: <Home />
      }
    ]
  }
]);