import { Link, useParams } from 'react-router-dom';

const ImageBox = ({imgSrc, label, target}) => {

  const { lang } = useParams();


  if(target) {
    return (
      <Link className="relative aspect-video group cursor-pointer" to={ "/" + lang + target }>
        <img src={ imgSrc } alt={ label } className="h-full w-full object-cover grayscale transition-all duration-700 group-hover:grayscale-0"/>
        <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-4">
          <div className="flex justify-center items-center border border-double border-white transition-all h-16 w-72 duration-500 bg-black bg-opacity-40 group-hover:border-gold group-hover:bg-opacity-70 group-hover:border-4 group-hover:w-full group-hover:h-full">
            <div className="uppercase font-semibold text-white text-3xl transition-all duration-500 whitespace-nowrap group-hover:text-gold">{ label }</div>
          </div>
        </div>
      </Link>);  
  }

  return (
    <div className="relative aspect-video group ">
      <img src={ imgSrc } alt={ label } className="h-full w-full object-cover grayscale transition-all duration-700 group-hover:grayscale-0"/>
      <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-4">
        <div className="flex justify-center items-center border border-double border-white transition-all h-16 w-72 duration-500 bg-black bg-opacity-40 group-hover:border-gold group-hover:bg-opacity-70 group-hover:border-4 group-hover:border-double group-hover:w-full group-hover:h-full">
          <div className="uppercase font-semibold text-white text-3xl transition-all duration-500 whitespace-nowrap group-hover:text-gold">{ label }</div>
        </div>
      </div>
    </div>);
};

export default ImageBox