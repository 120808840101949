import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

const Language = ({open, toggle}) => {

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
    const newPath = currentPath.replace(/^\/(en|es)/, "");
    navigate("/" + lang + newPath);
    toggle();
  };

  useEffect(() => {
    if ( open ) document.body.classList.add("overflow-hidden");
    if ( !open) document.body.classList.remove("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    }
  }, [open]);

  if(!open) return null;

  return (<div className="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-30 flex justify-center items-center z-[5500]">
    <div className="bg-gray-100 rounded-lg p-4">
      <div className={( i18n.language === "en" ? "bg-gray-50 " : "" ) + "cursor-pointer px-8 py-4 rounded-md text-center font-semibold text-xl hover:bg-white"} onClick={() => switchLanguage("en")}>
        English / Ingles
      </div>
      <div className={( i18n.language === "es" ? "bg-white " : "" ) + "cursor-pointer px-8 py-4 rounded-md text-center font-semibold text-xl hover:bg-white"} onClick={() => switchLanguage("es")}>
        Spanish / Español
      </div>
      <div className="cursor-pointer rounded-md px-8 py-2 text-center text-lg font-semibold bg-black text-white" onClick={toggle}>Close</div>
    </div>
  </div>);
};

export default Language;