import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import reportWebVitals from './config/reportWebVitals';
import { router } from './config/routes';
import { translations } from './i18n/index';

import "./config/style.css";

i18n.use(LanguageDetector).init({
  
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  load: 'languageOnly',
  resources: translations,
  fallbackLng: 'en',
  lowerCaseLng: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
