import { useParams } from "react-router-dom";
import SEO from "../components/seo/SEO";

const Privacy = () => {

  const { lang } = useParams();

  if(lang === "es") {
  return (
    <div className="bg-gray-200 py-4">
    <SEO pageTitle="Política de privacidad" pageDescription="Política de privacidad" noIndex />
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-4">Política de privacidad</h2>
      <p className="mb-4">
        Última actualización: 16/01/2024
      </p>
  
      <p className="mb-4">
        Tu privacidad es importante para nosotros. Esta Política de privacidad describe cómo recopilamos, utilizamos, divulgamos y protegemos tu información cuando visitas nuestro sitio web alojado en Google Firebase y utilizas diversos servicios de Google Cloud, incluido Google Analytics.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Información que recopilamos</h3>
  
      <p className="mb-4">
        <span className="font-bold">Información personal</span>: Podemos recopilar información personal, como tu nombre y dirección de correo electrónico, cuando la proporcionas con fines como suscripciones a boletines o formularios de contacto.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Datos de uso</h3>
  
      <p className="mb-4">
        Recopilamos automáticamente cierta información cuando visitas nuestro sitio web, incluidos datos de registro como tu dirección IP, tipo de navegador, páginas visitadas y el tiempo que pasaste en esas páginas.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Cómo usamos tu información</h3>
  
      <p className="mb-4">
        Utilizamos la información recopilada con diversos fines, como mejorar la experiencia del usuario, analizar el comportamiento del usuario para mejorar la funcionalidad y el contenido de nuestro sitio web, y comunicarnos contigo respondiendo a tus consultas, enviando boletines si estás suscrito y proporcionando actualizaciones.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Cookies y tecnologías de seguimiento</h3>
  
      <p className="mb-4">
        Utilizamos cookies y tecnologías de seguimiento similares para mejorar tu experiencia en nuestro sitio. Al utilizar nuestro sitio web, das tu consentimiento para el uso de cookies.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Google Firebase y servicios de Google Cloud</h3>
  
      <p className="mb-4">
        Nuestro sitio web está alojado en Google Firebase, y utilizamos varios servicios de Google Cloud, incluido Google Analytics. Google puede recopilar y procesar información sobre tu visita a nuestro sitio. Para obtener más información sobre cómo Google procesa tus datos, consulta la <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-500">Política de privacidad de Google</a>.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Tus opciones</h3>
  
      <p className="mb-4">
        Puedes gestionar tus preferencias de cookies a través de la configuración de tu navegador. Ten en cuenta que deshabilitar las cookies puede afectar la funcionalidad de nuestro sitio web.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Cambios en esta Política de privacidad</h3>
  
      <p className="mb-4">
        Podemos actualizar esta Política de privacidad para reflejar cambios en nuestras prácticas u otras razones operativas, legales o regulatorias. Consulta esta página periódicamente para conocer cualquier cambio.
      </p>
  
      <h3 className="text-lg font-bold mb-2">Contáctanos</h3>
  
      <p className="mb-4">
        Si tienes alguna pregunta sobre nuestra Política de privacidad, contáctanos.
      </p>
    </div>
  </div>
  
  )
  }
  return (
    <div className="bg-gray-200 py-4">
      <SEO pageTitle="Privacy Policy" pageDescription="Privacy Policy" noIndex />
      <div className="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
        <p className="mb-4">
          Last updated: 01/16/2024
        </p>

        <p className="mb-4">
          Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website hosted on Google Firebase and utilize various Google Cloud services, including Google Analytics.
        </p>

        <h3 className="text-lg font-bold mb-2">Information We Collect</h3>

        <p className="mb-4">
          <span className="font-bold">Personal Information</span>: We may collect personal information, such as your name and email address when you provide it for purposes like newsletter subscriptions or contact forms.
        </p>

        <h3 className="text-lg font-bold mb-2">Usage Data</h3>

        <p className="mb-4">
          We automatically collect certain information when you visit our website, including log data such as your IP address, browser type, pages visited, and the time spent on those pages.
        </p>

        <h3 className="text-lg font-bold mb-2">How We Use Your Information</h3>

        <p className="mb-4">
          We use the collected information for various purposes, including improving user experience, analyzing user behavior to enhance the functionality and content of our website, and communicating with you by responding to your inquiries, sending newsletters if subscribed, and providing updates.
        </p>

        <h3 className="text-lg font-bold mb-2">Cookies and Tracking Technologies</h3>

        <p className="mb-4">
          We use cookies and similar tracking technologies to improve your experience on our site. By using our website, you consent to the use of cookies.
        </p>

        <h3 className="text-lg font-bold mb-2">Google Firebase and Google Cloud Services</h3>

        <p className="mb-4">
          Our website is hosted on Google Firebase, and we utilize various Google Cloud services, including Google Analytics. Google may collect and process information about your visit to our site. For more information on how Google processes your data, please refer to <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-500">Google's Privacy Policy</a>.
        </p>

        <h3 className="text-lg font-bold mb-2">Your Choices</h3>

        <p className="mb-4">
          You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect the functionality of our website.
        </p>

        <h3 className="text-lg font-bold mb-2">Changes to This Privacy Policy</h3>

        <p className="mb-4">
          We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Please review this page periodically for any changes.
        </p>

        <h3 className="text-lg font-bold mb-2">Contact Us</h3>

        <p className="mb-4">
          If you have any questions about our Privacy Policy, please contact us.
        </p>
      </div>
    </div>
  )
}

export default Privacy
