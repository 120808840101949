import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsTranslate } from "react-icons/bs";
import { useState } from "react";
import Language from "./Language";
import SlideMenu from "./SlideMenu";
import AudioPlayer from "../audioplayer/AudioPlayer";


const Header = () => {

  const { t } = useTranslation("menu");
  const { pathname } = useLocation();
  const { lang } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageSelector, setLanguageSelector] = useState(false);

  const active = "cursor-pointer transition-color duration-150 px-6 border-2 border-black py-2 rounded hover:bg-black hover:text-gold";
  const inactive = "cursor-pointer transition-color duration-150 px-6 py-2 border-2 border-transparent rounded hover:bg-black hover:text-gold";

  return (
    <>
    <div className="sticky top-0 bg-white z-[5000] px-4">
      <div className="flex justify-between items-center max-w-screen-2xl mx-auto">
        <div className="font-isabel text-2xl xl:text-4xl tracking-widest uppercase flex items-center gap-8">
          <Link to={"/" + lang} className="cursor-pointer transition-colors duration-300 hover:text-gold">
            Hausy's
          </Link>
          <AudioPlayer />
        </div>
        
        
        <div className="hidden xl:flex xl:flex-1 justify-end items-center gap-2 py-4">
          <NavLink to={"/" + lang } className={(pathname === "/" + lang + "/" || pathname === "/" + lang)? active : inactive}>{ t("home") }</NavLink>
          <NavLink to={"/" + lang + "/location"} className={({ isActive }) => isActive ? active : inactive}>{ t("location") }</NavLink>
          <NavLink to={"/" + lang + "/events"} className={({ isActive }) => isActive ? active : inactive}>{ t("events") }</NavLink>
          <NavLink to={"/" + lang + "/food-and-drinks"} className={({ isActive }) => isActive ? active : inactive}>{ t("foodAndDrinks") }</NavLink>
          <NavLink to={"/" + lang + "/music"} className={({ isActive }) => isActive ? active : inactive}>{ t("music") }</NavLink>
          <NavLink to={"/" + lang + "/about"} className={({ isActive }) => isActive ? active : inactive}>{ t("aboutUs") }</NavLink>
        </div>
        <div className={(languageSelector ? "bg-black text-white " : "") + "transition-color duration-150 cursor-pointer ml-2 hover:bg-black px-4 py-2 rounded hover:text-white"} onClick={() => setLanguageSelector(true)}>
          <BsTranslate className="text-2xl"/>
        </div>
        <div className="xl:hidden">
          <div className="flex w-6 h-5 flex-col items-stretch justify-between cursor-pointer" onClick={() => setMenuOpen(prev => !prev)}>
            <div className="w-full rounded bg-black h-[3px] transition-all duration-200" style={(menuOpen ? {transformOrigin: "top left", transform: "translate(4px, 1px) rotate(45deg)"} : {})} />
            <div className="w-full rounded bg-black h-[3px] transition-all duration-150" style={(menuOpen ? {transform: "translateX(30px)", backgroundColor: "transparent"} : {})} />
            <div className="w-full rounded bg-black h-[3px] transition-all duration-200" style={(menuOpen ? {transformOrigin: "bottom left", transform: "translateX(4px) rotate(-45deg)"} : {})}/>
          </div>
        </div>
      </div>
    </div>
    <Language open={languageSelector} toggle={() => setLanguageSelector(false)} />
    <SlideMenu open={menuOpen} toggle={() => setMenuOpen(prev => !prev)} />
    </>
  )
}

export default Header