import { initializeApp } from "firebase/app";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// import { getStorage, connectStorageEmulator } from "firebase/storage";
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyD0V5IEVVBaRYllWT27_C4yMWSLLVNQ3ic",
  authDomain: "hausys-cafe-and-music-lounge.firebaseapp.com",
  projectId: "hausys-cafe-and-music-lounge",
  storageBucket: "hausys-cafe-and-music-lounge.appspot.com",
  messagingSenderId: "330694857332",
  appId: "1:330694857332:web:7260975fcc4146697ff721",
  measurementId: "G-YN11P715E9"
};

const app = initializeApp(firebaseConfig);
//const appCheck = initializeAppCheck(app, {provider: new ReCaptchaV3Provider('6LdM_XohAAAAAGuzEnhU7h7e7Us35qpRssg2uAlY'),isTokenAutoRefreshEnabled: true});
const analytics = getAnalytics(app);
//const auth = getAuth(app);
//const storage = getStorage(app);
//const perf = getPerformance(app);
//const functions = getFunctions(app);

export { app, analytics };
