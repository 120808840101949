import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';


const LanguageRedirect = ({children}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
  
    const preferredLanguage = navigator.language;
    const availableLanguages = ['en', 'es'];
    let matchingLanguage = availableLanguages.find(lang => preferredLanguage.includes(lang));
    if (!matchingLanguage) {
      matchingLanguage = 'en';
    }
    i18n.changeLanguage(matchingLanguage);
    navigate("/" + matchingLanguage + location.pathname);
    
    // eslint-disable-next-line
  }, []);

  return <div>Loading</div>;
};

export default LanguageRedirect;