import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ImgCarousel = ({data}) => {

  const [current, setCurrent] = useState(0);

  const moveCarousel = () => {
    return setCurrent(prev => {
      if(prev === data.length - 1) return 0; 
      return prev + 1;
    });
  }

  const handleSelect = (index) => {
    setCurrent(index);
  }

  useEffect(() => {
    const interval = setInterval(moveCarousel, 5000);
    return (() => {
      clearInterval(interval);
    })
    // eslint-disable-next-line
  },[]);

  return (
    <div className="flex flex-nowrap overflow-hidden relative bg-black">
      {
        data.map((item, index) => <div key={index}
          className="h-[60vh] min-w-full w-full flex items-center justify-center transition-transform duration-1000 ease-in relative"
          style={{transform: "translate(-" + (current * 100) + "%)"}}>
            <img src={item.imgSrc} alt={item?.title} className="w-full h-full object-cover" />
              <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-black bg-opacity-15">
                {
                  (item?.title) && 
                  <h2 className="text-6xl font-semibold text-white uppercase">{item?.title}</h2>
                }
                { 
                  (item?.target && item?.button) && 
                  <Link to={item?.target} className="transition-color duration-300 mt-12 p-6 bg-black text-white rounded hover:text-black hover:bg-gold font-semibold uppercase text-xl">{ item?.button }</Link>
                }
              </div>
            
        </div>)
      }
      <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-3">
        {
          data.map((_, index) => <div key={index} className={"h-3 w-3 rounded-full transition-all duration-1000 bg-gray-100 cursor-pointer " + (index === current ? "opacity-100" : "opacity-40")} onClick={() => handleSelect(index)} />)
        }
      </div>
    </div>
  )
}

export default ImgCarousel