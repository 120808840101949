
import { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import Header from '../header/Header'
import Footer from '../footer/Footer'
import LanguageRedirect from "../language/Language";
import Cookies from "../cookies/Cookies";

const Interface = () => {

  const { lang } = useParams();
  const { pathname } = useLocation();
  const availableLanguages = ['en', 'es'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if(!availableLanguages.includes(lang)) {
    return <LanguageRedirect />
  }

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <Cookies />
    </>
  )
}

export default Interface