
import Carousel from '../components/carousel/Carousel';
import ImageBox from '../components/imagebox/ImageBox';
import { useTranslation } from 'react-i18next';

import lounge from "../assets/images/couch.jpg"; 
import saxophone from "../assets/images/saxophone.jpg";
import cocktail from "../assets/images/cocktail.jpg";

import rock from "../assets/images/rock.jpg";
import salsa from "../assets/images/salsa.jpg";
import piano from "../assets/images/piano.jpg";
import guitar from "../assets/images/guitar.jpg";
import folk from "../assets/images/folk.jpg";
import SEO from '../components/seo/SEO';

const Home = () => {

  const { t } = useTranslation(["home", "menu"]);

  const data = [
    {imgSrc: lounge, label: t("menu:location"), target: "/location"},
    {imgSrc: saxophone, label: t("menu:events"), target: "/events"},
    {imgSrc: cocktail, label: t("menu:foodAndDrinks"), target: "/food-and-drinks"}
  ];

  const musicData = [
    {imgSrc: saxophone, label: "Jazz", target: "/music"},
    {imgSrc: rock, label: "Rock", target: "/music"},
    {imgSrc: salsa, label: "Salsa", target: "/music"},
    {imgSrc: piano, label: "Classical", target: "/music"},
    {imgSrc: folk, label: "Country", target: "/music"},
    {imgSrc: guitar, label: "Folk", target: "/music"}
  ];

  return (
    <div className="bg-gray-200">
      <SEO pageTitle={ t("home:page_title")} pageDescription={ t("home:page_description")} pageImage={ cocktail } />
        <Carousel />
      
      <div className="p-4">
        <div className="max-w-screen-2xl mx-auto">
          <h1 className="text-center font-isabel tracking-widest text-4xl mt-4 uppercase">{ t("home:discoverHausys") }</h1>
          <p className="max-w-screen-xl mx-auto my-4">{ t("home:phrase1") }</p>
          <p className="max-w-screen-xl mx-auto my-4">{ t("home:phrase2") }</p>
          <p className="max-w-screen-xl mx-auto my-4">{ t("home:phrase3") }</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 my-8">
        {
          data.map((data, index) => <ImageBox imgSrc={data.imgSrc} label={data.label} target={data.target} key={index} />)
        }

      </div>
      <div className="bg-gray-700 p-4">
        <div className="max-w-screen-2xl mx-auto pt-8">
          <h2 className="text-white text-center font-isabel tracking-widest text-4xl mt-4 uppercase">{ t("musicAtHausys") }</h2>
          <p className="text-white max-w-screen-xl mx-auto my-4 leading-relaxed">{ t("music") }</p>
        </div>
      </div>
      <div className="grid bg-gray-700 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 py-8">
        {
          musicData.map((data, index) => <ImageBox imgSrc={data.imgSrc} label={data.label} target={data?.target} key={index} />)
        }

      </div>
    </div>
  )
}

export default Home