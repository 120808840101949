import { useTranslation } from "react-i18next"
import { SiGooglemaps, SiApple } from "react-icons/si";
import SEO from "../components/seo/SEO";
import lounge from "../assets/images/location.jpg"; 
import couch from "../assets/images/couch.jpg";
import peopleBar from "../assets/images/peopleBar.jpg";
import ImgCarousel from "../components/carousel/ImgCarousel";

const Place = () => {

  const { t } = useTranslation("location");

  const data = [
    {imgSrc: lounge},
    {imgSrc: couch},
    {imgSrc: peopleBar}
  ];

  return (
    <>
    <SEO pageTitle={ t("page_title") } pageDescription={ t("page_description") } pageImage={ couch } />
    <div className="bg-gray-200">
      <ImgCarousel data={data} />
        <div className="max-w-screen-xl mx-auto bg-white px-4 py-12">

      <div className="mb-8">
        <h1 className="font-isabel tracking-widest text-5xl mb-4 uppercase">{t('text_section_1_title')}</h1>
        <p className="text-lg">{t('text_section_1_content')}</p>
      </div>

      <img src={couch} alt="HAUSY'S Lounge Medellín" className="block w-full lg:w-1/3 h-auto mb-4 lg:float-left lg:mb-4 lg:mr-4"/>

      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_2_title')}</h2>
        <p className="text-lg">{t('text_section_2_content')}</p>
      </div>


      {/* Section 3 */}
      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_3_title')}</h2>
        <p className="text-lg mb-8">{t('text_section_3_content')}</p>
        <p className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_3_subTitle')}</p>
        <p className="text-lg">{t('text_section_3_content2')}</p>
      </div>

      {/* Section 4 */}
      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_4_title')}</h2>
        <p className="text-lg">{t('text_section_4_content')}</p>
      </div>

      {/* Section 5 */}
      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_5_title')}</h2>
        <p className="text-lg">{t('text_section_5_content')}</p>
        <div className="flex flex-row items-start justify-start gap-2 mt-4">
          <a href="https://maps.apple.com/?address=Calle%2010,%20Medell%C3%ADn,%20Antioquia,%20Colombiall=6.208558,-75.563393" target="_blank" rel="noreferrer nofollow" className="border rounded-md text-lg border-gray-700 text-gray-900 px-2 py-1 inline-flex items-center gap-2 hover:bg-black hover:text-white"><SiApple /> Apple Maps</a>
          <a href="https://maps.app.goo.gl/NbGySVKTUdRNF1Rd8" target="_blank" rel="noreferrer nofollow" className="border rounded-md text-lg border-gray-700 text-gray-900 px-2 py-1 inline-flex items-center gap-2 hover:bg-black hover:text-white"><SiGooglemaps /> Google Maps</a>
        </div>
      </div>
    </div>
    </div>
    </>
  )
}

export default Place