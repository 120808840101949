import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SEO = ({ pageTitle, pageDescription, pageImage, noIndex = false }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const pageLanguage = currentPath.split("/")[1];
  const pathWithoutLanguage = currentPath.replace(`/${pageLanguage}`, "");
  const alternateLanguages = ["en", "es"]; //.filter(lang => lang !== pageLanguage);

  return (
    <Helmet>
      <html lang={pageLanguage} />

      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      {/* Robots meta tag */}
      <meta name="robots" content={(noIndex ? "noindex" : "index") + " follow nositelinkssearchbox"}/>

      {/* Open Graph */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      {pageImage && <meta property="og:image" content={pageImage} />}
      <meta property="og:url" content={"https://www.hausyscafe.com" + currentPath} />
      <meta property="og:locale" content={pageLanguage} />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      {pageImage && <meta name="twitter:image" content={pageImage} />}

      {/* Canonical Link */}
      <link rel="canonical" href={"https://www.hausyscafe.com/en" + pathWithoutLanguage} />

      {/* hreflang tags */}
      {alternateLanguages.map(lang => 
        <link key={lang} rel="alternate" href={"https://www.hausyscafe.com/" + lang + pathWithoutLanguage} hrefLang={lang} />
      )}

    </Helmet>
  );
};

export default SEO;
