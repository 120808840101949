import { useTranslation } from "react-i18next";
import SEO from "../components/seo/SEO";
import cocktail from "../assets/images/cocktail.jpg"; 
import dish from "../assets/images/dish.jpg";
import couch from "../assets/images/couch.jpg";
import ImgCarousel from "../components/carousel/ImgCarousel";

const About = () => {

  const { t } = useTranslation("about");

  const data = [
    {imgSrc: dish},
    {imgSrc: couch},
    {imgSrc: cocktail}
  ];

  return (
    <>
    <SEO pageTitle={ t("page_title") } pageDescription={ t("page_description") } pageImage={ couch } />
    <div className="bg-gray-200">
      <ImgCarousel data={data} />
        <div className="max-w-screen-xl mx-auto bg-white px-4 py-12">

      <div className="mb-8">
        <h1 className="font-isabel tracking-widest text-4xl mb-4 uppercase">{t('text_section_1_title')}</h1>
        <p className="text-lg">{t('text_section_1_content')}</p>
      </div>

      <img src={couch} alt="HAUSY'S Lounge Medellín" className="block w-full lg:w-1/3 h-auto mb-4 lg:float-left lg:mb-4 lg:mr-4"/>

      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_2_title')}</h2>
        <p className="text-lg">{t('text_section_2_content')} {t('text_section_3_content')}</p>
      </div>



      {/* Section 4 */}
      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_4_title')}</h2>
        <p className="text-lg">{t('text_section_4_content')}</p>
      </div>

      {/* Section 5 */}
      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_5_title')}</h2>
        <p className="text-lg">{t('text_section_5_content')}</p>

      </div>

      {/* Section 6 */}
      <div className="mb-8">
        <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">{t('text_section_6_title')}</h2>
        <p className="text-lg">{t('text_section_6_content')}</p>

      </div>
    </div>
    </div>
    </>
  )
}

export default About