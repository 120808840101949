
import rock from "../assets/images/rock.jpg";
import salsa from "../assets/images/salsa.jpg";
import piano from "../assets/images/piano.jpg";
import guitar from "../assets/images/guitar.jpg";
import folk from "../assets/images/folk.jpg";
import saxophone from "../assets/images/saxophone.jpg";

import SEO from '../components/seo/SEO';
import { useTranslation } from "react-i18next";
import ImgCarousel from "../components/carousel/ImgCarousel";
import ImageBox from "../components/imagebox/ImageBox";

const Music = () => {

  const { t } = useTranslation("music");

  const musicData = [
    {imgSrc: saxophone, label: "Jazz", target: "/music"},
    {imgSrc: rock, label: "Rock", target: "/music"},
    {imgSrc: salsa, label: "Salsa", target: "/music"},
    {imgSrc: piano, label: "Classical", target: "/music"},
    {imgSrc: folk, label: "Country", target: "/music"},
    {imgSrc: guitar, label: "Folk", target: "/music"}
  ];

  const Highlight = ({phrase = "", words = []}) => {
    
    const parts = phrase.split(" ");

    const styling = {
      rock: "text-violet-500",
      jazz: "text-pink-500",
      soulful: "text-amber-500",
      conmovedoras: "text-amber-500",
      salsa: "text-red-500",
      classical: "text-sky-500",
      clásica: "text-sky-500",
      folkloric: "text-emerald-500",
      folklórica: "text-emerald-500",
      campestre: "text-fuchsia-500",
      country: "text-fuchsia-500"
    };

    return (
      parts.map((string, index) => {
        if(words.includes(string)) return (<span className={styling[string.toLocaleLowerCase()] + " font-semibold"} key={index}>{ string } </span>)
        else return (<span key={index}>{string} </span>)
      })
    );
  }

  return (
    <>
    <SEO pageTitle={ t("page_title") } pageDescription={ t("page_description") } pageImage={ saxophone } />
    <div className="bg-white">

      <ImgCarousel data={musicData} />
      <div className="max-w-screen-xl mx-auto px-4 pt-12">
        <div className="mb-8">
          <h1 className="font-isabel tracking-widest text-3xl mb-4 uppercase">
            <Highlight phrase={t('text_section_1_title')} words={["Rock"]} />
            </h1>
          <p className="text-lg">{t('text_section_1_content')}</p>
        </div>

        <img src={saxophone} alt="HAUSY'S Lounge Medellín" className="block w-full lg:w-1/3 h-auto mb-4 lg:float-left lg:mb-4 lg:mr-4"/>

        <div className="mb-8">
          <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">
            <Highlight phrase={t('text_section_2_title')} words={["Jazz", "Soulful", "Conmovedoras"]} />
            </h2>
          <p className="text-lg">{t('text_section_2_content')}</p>
        </div>


        {/* Section 3 */}
        <div className="mb-8">
          <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">
            <Highlight phrase={t('text_section_3_title')} words={["Salsa"]} />
            </h2>
          <p className="text-lg">{t('text_section_3_content')}</p>
        </div>

        {/* Section 4 */}
        <div className="mb-8">
          <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">
            <Highlight phrase={t('text_section_4_title')} words={["Classical", "Clásica"]} /></h2>
          <p className="text-lg">{t('text_section_4_content')}</p>
        </div>

        {/* Section 5 */}
        <div className="mb-8">
          <h2 className="font-isabel tracking-widest text-3xl mb-4 uppercase">
            <Highlight phrase={t('text_section_5_title')} words={["Folkloric","Folklórica","Campestre","Country"]} /></h2>
          <p className="text-lg">{t('text_section_5_content')}</p>
          
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 py-8">
        {
          musicData.map((data, index) => <ImageBox imgSrc={data.imgSrc} label={data.label} key={index} />)
        }

      </div>
    </div>
    </>
  )
}

export default Music