function loadTranslations() {
  const context = require.context('./', true, /\.json$/);
  const resources = {};

  context.keys().forEach(key => {
    
    const doc = key.replace('./', '').replace('.json', '');
    const translations = context(key);
    const [language, document] = doc.split('/');
    
    Object.keys(translations).forEach(item => {
      
      if (!resources[language]) resources[language] = {};
      
      if (!resources[language][document]) resources[language][document] = {};

      resources[language][document][item] = translations[item];

    });
  });

  return resources;
}

export const translations = loadTranslations();