import React, { useEffect, useRef, useState } from 'react';
import { FaPlay, FaPause } from "react-icons/fa6";
import audio from '../../assets/mp3/danceWithMe.mp3';
import { useTranslation } from 'react-i18next';

const AudioPlayer = () => {

  const { t } = useTranslation('menu');
  
  const audioRef = useRef();
  const [isPlaying, setPlaying] = useState(false);
  const [showPlay, setShowPlay] = useState('icon');
  const [string, setString] = useState('');
  const [timer, setTimer] = useState(3000);
  // const [showContext, setContext] = useState(true);

  const playAudio = () => {
    audioRef.current.play();
  };
  const pauseAudio = () => {
    audioRef.current.pause();
  };

  const audioControl = () => {
    setPlaying(prev => {
      if(prev) {
        pauseAudio();
        return !prev;
      } else {
        playAudio();
        return !prev;
      }
    });
  }

  useEffect(() => {
    if(!isPlaying) {
      setTimeout(() => {
        setTimer(prev => {
          setString(() => (prev === 0 ? ' ' : t('press-to-play').substring(0, (31 - (prev / 100)))));
          if(prev <= 0) {
            setShowPlay(prev => !prev);
            return 3000;
          } else {
            return prev - 100;
          }
        });
      }, 100);
    } else return;
  },[t, timer, isPlaying]);

  return (
    <div className='relative bg-gold text-black transition-color duration-150 cursor-pointer ml-2 hover:bg-black px-4 h-10 text-lg flex items-center rounded hover:text-gold' onClick={audioControl} title='Orleans - Dance With Me (Live 1975)'>
      <audio ref={audioRef} src={audio} loop />
      { isPlaying ? <FaPause /> : ((!showPlay && window.innerWidth > 648) ? string : <FaPlay />) }
    </div>
  );
};

export default AudioPlayer;
