import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'

const SlideMenu = ({open, toggle}) => {

  const location = useLocation();
  const { lang } = useParams();
  const { t, i18n } = useTranslation("menu");
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
    const newPath = currentPath.replace(/^\/(en|es)/, "");
    navigate("/" + lang + newPath);
    toggle();
  };

  useEffect(() => {
    if(open) toggle();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if ( open ) document.body.classList.add("overflow-hidden");
    if ( !open) document.body.classList.remove("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    }
  }, [open]);

  const active = "cursor-pointer transition-color duration-150 px-6 border-2 border-black py-4 rounded hover:bg-black hover:text-white";
  const inactive = "cursor-pointer transition-color duration-150 px-6 py-4 border-2 border-transparent rounded hover:bg-black hover:text-white";

  return (
    <div className="fixed w-full h-screen top-0 bottom-0 left-0 right-0 overflow-x-hidden overflow-y-auto xl:hidden bg-gray-100 p-4 pt-20 transition-all duration-500 ease-in-out z-[4500]" style={(open ? {} : {transform: "translateX(-100%)"})}>
      <div className="flex flex-col gap-2 text-xl text-center">
        <NavLink to={"/" + lang }  className={inactive}>Home</NavLink>
        <NavLink to={"/" + lang + "/location"} className={({ isActive }) => isActive ? active : inactive}>{ t("location") }</NavLink>
        <NavLink to={"/" + lang + "/events"} className={({ isActive }) => isActive ? active : inactive}>{ t("events") }</NavLink>
        <NavLink to={"/" + lang + "/food-and-drinks"} className={({ isActive }) => isActive ? active : inactive}>{ t("foodAndDrinks") }</NavLink>
        <NavLink to={"/" + lang + "/music"} className={({ isActive }) => isActive ? active : inactive}>{ t("music") }</NavLink>
        <NavLink to={"/" + lang + "/about"} className={({ isActive }) => isActive ? active : inactive}>{ t("aboutUs") }</NavLink>
        <hr className="border border-black my-4" />
        <div className={( i18n.language === "en" ? active : inactive )} onClick={() => switchLanguage("en")}>
          English / Ingles
        </div>
        <div className={( i18n.language === "es" ? active : inactive )} onClick={() => switchLanguage("es")}>
          Spanish / Español
        </div>
      </div>
    </div>
  )
}

export default SlideMenu