import { SiGooglemaps, SiApple, SiInstagram, SiWhatsapp } from "react-icons/si"; //SiFacebook,
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsEnvelope } from "react-icons/bs";
import OpenSign from "./OpenSign";
import HausyFull from "../../assets/images/HausyFull.svg";

const Footer = () => {

  const { t } = useTranslation("main"); 
  const { lang } = useParams();

  return (
    <div className="w-full bg-black py-4 text-gray-300 lg:pt-8">
      <div className="grid grid-flow-row md:grid-cols-2 xl:grid-cols-3 justify-between items-start max-w-screen-2xl mx-auto px-4 gap-4">
        <div className="col-span-1 flex items-stretch gap-4">
          <Link to={"/" + lang } className="block flex-1 cursor-pointer">
          <img src={HausyFull} alt="Hausy's Cafe Music Lounge" className="w-full h-auto" />
          </Link>
          <div className="flex-1 py-2">
            <ul className="flex h-full flex-col justify-between text-2xl text-white">
              <li>
                <a href="https://www.instagram.com/hausyscafe" rel="noreferrer nofollow" target="_blank" className="inline-block cursor-pointer hover:text-pink-400"><SiInstagram /></a>
              </li>
              {
                /*
              <li>
                <a href="https://facebook.com" rel="noreferrer nofollow" target="_blank" className="inline-block cursor-pointer hover:text-blue-500"><SiFacebook /></a>
              </li>
                */
              }
              <li>
                <a href="https://wa.me/573217598421" rel="noreferrer nofollow" target="_blank" className="inline-block cursor-pointer hover:text-green-400"><SiWhatsapp /></a>
              </li>
              <li>
                <a href="mailto:welcome@hausyscafe.com" rel="noreferrer nofollow" target="_blank" className="inline-block cursor-pointer hover:text-gold"><BsEnvelope /></a>
              </li>
            </ul>
          </div>
          
        </div>
        <div className="">
          <OpenSign />
          <h3 className="font-semibold mb-2 mt-4">{ t("openingHours") }</h3>
          <div className="grid grid-cols-2">
            <div>{ t("monday") }</div><div>7:30 am - 10:00 pm</div>
            <div>{ t("thuesday") }</div><div>7:30 am - 10:00 pm</div>
            <div>{ t("wednesday") }</div><div>7:30 am - 10:00 pm</div>
            <div>{ t("thursday") }</div><div>7:30 am - 10:00 pm</div>
            <div>{ t("friday") }</div><div>7:30 am - 2:00 am</div>
            <div>{ t("saturday") }</div><div>7:30 am - 2:00 am</div>
            <div>{ t("sunday") }</div><div>7:30 am - 10:00 pm</div>

          </div>
        </div>
        <div className="">
          <div className="flex flex-col lg:flex-row items-start justify-start gap-2 mb-3">
            <a href="https://maps.apple.com/?address=Calle%2010,%20Medell%C3%ADn,%20Antioquia,%20Colombiall=6.208558,-75.563393" target="_blank" rel="noreferrer nofollow" className="border rounded-md text-sm border-gray-400 text-gray-300 px-2 py-1 inline-flex items-center gap-2 hover:border-gray-100 hover:text-white"><SiApple /> Apple Maps</a>
            <a href="https://maps.app.goo.gl/NbGySVKTUdRNF1Rd8" target="_blank" rel="noreferrer nofollow" className="border rounded-md text-sm border-gray-400 text-gray-300 px-2 py-1 inline-flex items-center gap-2 hover:border-gray-100 hover:text-white"><SiGooglemaps /> Google Maps</a>
          </div>
            
            <ul>
              <li>Centro Comercial Vizcaya</li>
              <li>Local 9928</li>
              <li>Calle 10 #32-115</li>
              <li>El Poblado, Medellín</li>
            </ul>
          
          <div className="mt-4">
            <Link to={"/" + lang + "/privacy"} className="text-gray-600 text-sm hover:text-white">{ t("privacy") }</Link>
          </div>

        </div>
      </div>
      <div className="text-center text-xs pt-8 pb-2 text-gray-600">
        <a href="https://www.markenbreit.com/" target="_blank" rel="noreferrer follow" className="group hover:text-white">{ t("designConcept") }<span className="font-semibold group-hover:text-[#dafe09]">MARKEN</span><span className="font-semibold">BREIT.COM</span></a>
      </div>
    </div>
  )
}

export default Footer