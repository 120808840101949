import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const Cookies = () => {

  const { t } = useTranslation("main");
  const { lang } = useParams();
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent') === 'true';

    if (hasConsent) {
      setShowBanner(false);
    }
  }, []);

  useEffect(() => {
    if ( showBanner ) document.body.classList.add("overflow-hidden");
    if ( !showBanner) document.body.classList.remove("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showBanner]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };
  const closeBanner = e => {
    setShowBanner(false);
    return true;
  }

  if (!showBanner) {
    return null;
  }

  return (<div className="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-30 flex justify-center items-center z-[5500]">
    <div className="bg-gray-100 rounded-lg p-4 max-w-full sm:max-w-screen-sm">
      <p className="text-center text-xl font-semibold">{ t("cookiesTitle") }</p>
      <p className="my-6 text-center">{ t("cookiesText") }</p>
      <div className="mt-4 flex items-stretch">
        <Link to={"/" + lang + "/privacy"} onClick={closeBanner} className="cursor-pointer rounded-l-md py-2 px-8 text-center font-semibold bg-gray-300 text-gray-700 hover:bg-gray-400">{ t("cookiesBtnMore") }</Link>
        <div className="flex-1 cursor-pointer rounded-r-md py-2 text-center text-lg font-semibold bg-gray-900 text-gray-200 hover:bg-black hover:text-white" onClick={handleAccept}>{ t("cookiesBtnAccept") }</div>
      </div>
    </div>
  </div>);

};

export default Cookies;
